@import "./shared/styles/Variables.scss";

//Shared components
// Modals
.custom-modal-style {
  .uk-modal-title {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .sub-heading {
    margin-bottom: 0.2em;
    text-transform: uppercase;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }
}

.back {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: $secondary-btn;
  line-height: 1;
  margin: 0;
  span {
    transition: 0.3s all ease-in-out;
  }
  &:hover {
    cursor: pointer;
    span {
      margin-right: 5px;
    }
  }
}

.uk-input,
.uk-select,
.uk-textarea,
button {
  border-radius: $border-radius !important;
  text-transform: none !important;
}

.uk-card-outlined {
  border: 1px solid $light-bg;
}

.uk-card-muted {
  background-color: $muted-color;
}

.uk-card {
  border-radius: $border-radius;
}

th {
  text-transform: capitalize !important;
}

label {
  text-transform: capitalize !important;
}

.clear-filter {
  background-color: transparent;
  border: none;
  text-transform: none;
  color: $primary-color;
  margin: 0em;
  padding: 0;
  font-weight: $font-weight-heading;
  &:hover {
    cursor: pointer;
  }
}
