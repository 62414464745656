// Variables
$button-border-radius: 0.5em;

// Font sizes
$title: 2rem;
$subtitle: 1.2rem;
$body-fontsize: 0.9rem;

$mobile-title: 1.2rem;
$mobile-subtitle: 0.9rem;

$font-weight-heading: 600;
$font-weight-subheading: 500;

$line-height: 1.5em;

// Margins
$margin-1: 7em;
$margin-2: 5em;
$margin-3: 0.5em;

// Colors
// $primary-color: #f36c24;
$primary-color: #e41f25;
$secondary-color: #4365ae;
$darker-secondary-color: #4365ae;
$grey-color: #a9a9ab;
$dark-grey-color: #666;
$light-grey-color: #ccc;
$black-color: #000;
$white-color: #fff;
$muted-color: #f8f8fa;
$light-bg: #efefef;
$hover-color: #ffffdd;

// Button colors
$secondary-btn: #4365ae;
$secondary-btn-hover: #35508b;
$danger-btn: #e41f25;
$danger-btn-hover: #de2227;

$success-color: #32d296;
$danger-color: #dc3545;
$warning-color: #faa05a;

$border-radius: 0.2em;
