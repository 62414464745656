@import "./shared//styles/Variables.scss";

*,
*::after,
*::before {
  box-sizing: border-box;
}
html,
body {
  font-size: 0.92rem;
  margin: 0;
  width: 100%;
}
body {
  margin: 0;
  // font-family: "Verdana", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Prevent modal from snapping page to top.
body.modal-open {
  overflow: visible;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.uk-offcanvas-bar-light {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #f8f8f8;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out;
}
@media (min-width: 960px) {
  .uk-offcanvas-bar-light {
    left: -350px;
    width: 350px;
    padding: 40px 40px;
  }
}
.uk-offcanvas-flip .uk-offcanvas-bar-light {
  left: auto;
  right: -270px;
}
.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right;
}
.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar-light {
  left: auto;
  right: 0;
}
@media (min-width: 960px) {
  .uk-offcanvas-flip .uk-offcanvas-bar-light {
    right: -350px;
  }
}
.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar-light {
  left: auto;
  right: 0;
}
.uk-offcanvas-reveal .uk-offcanvas-bar-light {
  left: 0;
}
.uk-open > .uk-offcanvas-bar-light {
  left: 0;
}

.uk-section-muted {
  background-color: $light-bg;
}
.uk-card-secondary {
  background-color: $black-color;
}

.uk-button {
  padding: 0em 1em;
  line-height: 2.2em;
  border: 0;
  font-weight: $font-weight-heading;
  transition: 0.3s all ease-in-out;
  &.secondary {
    background-color: inherit;
    color: $secondary-btn;
    &:hover {
      background-color: $secondary-btn;
      color: $white-color;
    }
  }
  &.primary {
    color: $white-color;
    background-color: $secondary-btn;
    &:hover {
      background-color: $secondary-btn-hover;
      color: $white-color;
    }
  }
  &.danger {
    color: $white-color;
    background-color: $danger-color;
    &:hover {
      background-color: $danger-btn-hover;
      color: $white-color;
    }
  }
  &.text {
    padding: 0 !important;
    background-color: inherit !important;
    color: $secondary-btn;
    &:hover {
      text-decoration: underline;
    }
  }
  &.text-danger {
    padding: 0 !important;
    background-color: inherit !important;
    color: $danger-btn;
    &:hover {
      text-decoration: underline;
    }
  }
}

// Scrollbar
* > ::-webkit-scrollbar {
  width: 0.6em !important;
  height: 0.6em !important;
}

* > ::-webkit-scrollbar-track {
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.2);
}

* > ::-webkit-scrollbar-thumb {
  background-color: $grey-color;
  outline: none;
  border-radius: $border-radius;
}

//
.status {
  font-size: 0.8rem;
  height: 10px;
  width: 10px;
  border-radius: 20px;
  color: $white-color;
  padding: 0.5em 1em;
  font-weight: $font-weight-heading;
  white-space: nowrap;

  &.red {
    background-color: $danger-color !important;
  }
  &.blue {
    background-color: $primary-color !important;
  }
  &.green {
    background-color: $success-color !important;
  }
  &.warning {
    background-color: $warning-color !important;
  }
  &.archived {
    background-color: $grey-color !important;
  }
}

// .status {
//   position: relative;
//   padding: 0;
//   font-weight: $font-weight-heading;
//   &::before {
//     position: absolute;
//     content: "";
//     height: 10px;
//     width: 10px;
//     left: -15px;
//     top: 50%;
//     transform: translateY(-50%);
//     border-radius: 20px;
//     color: $white-color;
//   }
//   &.red::before {
//     background-color: $danger-color !important;
//   }
//   &.blue::before {
//     background-color: $primary-color !important;
//   }
//   &.green::before {
//     background-color: $success-color !important;
//   }
//   &.warning::before {
//     background-color: $warning-color !important;
//   }
//   &.archived::before {
//     background-color: $grey-color !important;
//   }
// }
